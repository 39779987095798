export default {
  firebase_error_codes: {
    'auth/email-already-in-use': 'The email address is already in use by another account.',
    'auth/operation-not-allowed': 'Password login is disabled for this project.',
    'auth/too-many-requests': 'We have blocked all requests from this device due to unusual activity. Try again later.',
    'auth/user-not-found': 'The user is unknown.',
    'auth/wrong-password': 'The password is invalid.',
    'auth/user-disabled': 'The user account has been disabled by the administrator.',
    'auth/expired-action-code': 'The password reset code has expired.',
    'auth/invalid-action-code': 'The password reset code is invalid. Code is malformed or has already been used.',
    'auth/user-token-expired': 'The user\'s credential is no longer valid. The user must sign in again.',
    'auth/invalid-user-token': 'An invalid refresh token is provided.',
    'auth/invalid-credential': 'The grant type specified is invalid.',
  },
  navigation: {
    top: {
      bookmarks_text: 'Bookmarks',
      account_settings_text: 'Account settings',
      sign_in_text: 'Login',
      sign_out_text: 'Logout',
      language_selector: {
        de: 'German',
        en: 'English',
        fr: 'français',
      },
    },
    bottom: {
      home_text: 'Home',
      assembly_text: 'Constructions',
      tools_text: 'Tools',
      bookmark_text: 'Bookmarks',
      next_button: {
        main_text: 'Continue',
        result_count_text: 'Components',
        result_count_empty_text: 'Components found',
      },
    },
    view_titles: {
      home_text: '',
      assembly_search: {
        manual_text: 'Manual search',
        preset_text: 'Preset search',
      },
      reset_password_text: 'Change password',
      impressum_text: 'Terms & Conditions',
      data_protection_text: 'Data Privacy',
      tools_text: '',
      product_detail_text: 'Product Details',
      bookmarks_text: 'Bookmarks',
      data_privacy_text: 'Datenschutz',
      search_results: 'Suchergebnisse',
    },
  },
  modal: {
    authentication: {
      form: {
        firstName: {
          placeholder_text: 'First Name',
          is_empty_text: 'Please enter your First Name  ',
        },
        lastName: {
          placeholder_text: 'Last Name',
          is_empty_text: 'Please enter your Last Name  ',
        },
        company_name: {
          placeholder_text: 'Company name',
          is_empty_text: 'Please enter your company name  ',
        },
        street: {
          placeholder_text: 'Street',
          is_empty_text: 'Please enter your street  ',
        },
        zip: {
          placeholder_text: 'Zip code',
          is_empty_text: 'Please enter your zip code  ',
        },
        city: {
          placeholder_text: 'City',
          is_empty_text: 'Please enter your city  ',
        },
        country: {
          placeholder_text: 'Country',
          is_empty_text: 'Please select your country  ',
        },
        phone_number: {
          placeholder_text: 'Phone number',
          is_empty_text: 'Please enter your phone number  ',
        },
        email: {
          placeholder_text: 'Email address',
          is_empty_text: 'Please enter a valid email address',
        },
        dataProtect: {
          is_empty_text: 'Please accept the data privacy policy',
        },
        password: {
          placeholder_text: 'Password',
          description_text: 'Use eight or more characters with a mixture of letters, numbers and symbols',
          is_invalid_text: 'Please enter a valid password',
        },
        error_text: 'Not all fields were filled out correctly. Please check your details.',
      },
      login: {
        title_text: 'Login',
        description_text: 'Please log in to edit your project lists or data.',
        button_text: 'Log in',
        no_account_yet_text: 'Don\'t have an account yet?',
        register_here_text: 'Register here',
        forgot_password_text: 'Forgot your password?',
        success: {
          title_text: 'Login Successful',
          main_text: 'Welcome {userName}',
          description_text: 'You can now save project lists and building components and access your profile.',
        },
      },
      register: {
        title_text: 'Registration',
        description_text: 'Please register to save building components in project lists. ',
        button_text: 'Register',
        data_protection: {
          main_text: 'I have read and accepted the {dataProtectionLink} policy.',
          label_text: 'data privacy',
          label_link: 'https://www.swisskrono.com/ch-en/data-privacy/#',
        },
        success: {
          title_text: 'Login successful',
          main_text: 'Welcome {userName}',
          description_text: 'You can now save project lists and building components and access your profile.',
        },
      },
      getUpdates: {
        data_protection: {
          main_text: 'Consent option to be informed about news by email or post',
          label_text: 'data privacy',
          label_link: 'https://www.swisskrono.com/ch-en/data-privacy/#',
        },
      },
      login_register_success: {
        first_part_text: 'We advise you to',
        second_part_text: 'bookmark constructions',
        third_part_text: 'for faster access.',
      },
      reset_password_send_email: {
        title_text: 'Reset Password',
        description_text: 'Please enter the email address associated with your account. You will then receive a link for changing your password.',
        back_to_login_text: 'Back to login',
        button_text: 'Send',
        success: {
          title_text: 'Reset password',
          main_text: 'Link successfully sent',
          description_text: 'Please check the email we sent you.',
        },
      },
      reset_password_set_password: {
        description_text: 'Please enter a new password here. Your old one will be overwritten.',
        button_text: 'Update',
      },
    },
  },
  home: {
    welcome_text: 'Welcome to',
    main_text: 'SWISS KRONO\nplanning',
    predefined_search: {
      main_text: 'Assembly filter',
      region_selector_placeholder_text: 'Select a region',
    },
    manual_search: {
      main_text: 'Manually search building components',
      link_text: 'To the catalogue',
    },
    registration: {
      main_text: 'Create an account and project lists',
      link_text: 'Register',
    },
  },
  bookmarks: {
    title_text: 'Hello, {userName}',
    lists_title_text: 'Bookmarks',
    general_list_text: 'General list',
    new_list_text: 'New list',
    rename_list_text: 'Rename list',
    delete_list_text: 'Delete list',
    empty_list: {
      main_text: 'No building components have been saved to this list yet.',
      description_text: 'Add components',
      predefined_search_text: 'Filter',
      manual_search_text: 'Manual search',
    },
    modal: {
      form: {
        name: {
          placeholder_text: 'Name',
          is_empty_text: 'Please enter a name',
        },
      },
      new_bookmark_list: {
        title_text: 'Create new list',
        description_text: 'Please enter the name of your new watch list',
        button_text: 'Save',
        back_to_save_bookmarked_product_text: 'Back',
      },
      rename_bookmark_list: {
        title_text: 'Rename list',
        description_text: 'Enter a new name',
        button_text: 'Save',
      },
      delete_bookmark_list: {
        title_text: 'Delete list',
        description_text: 'Are you sure you want to delete "{bookmarkListName}"? All components stored in this list will be removed.',
        button_text: 'Delete',
      },
      save_bookmarked_product: {
        title_text: 'Save constructions',
        description_text: 'Choose a list for saving the building component.',
        button_text: 'Save',
      },
    },
  },
  constructions_search: {
    steps: {
      first_step: {
        main_text: 'Basic data',
        helper_text: 'Define the details of the construction project',
      },
      second_step: {
        main_text: 'Building components',
        helper_text: 'Select a component category to determine requirements',
        min_requirements_text: 'Legal minimum requirements',
        airborne_sound_insulation_text: 'Airborne sound',
        footfall_sound_text: 'Impact sound',
        fire_resistance_text: 'Fire resistance',
        fire_resistance_class_text: 'Class',
      },
      third_step: {
        main_text: 'Requirements',
        helper_text: 'Fire and sound insulation values are determined automatically',
        filter_form: {
          description_text: 'Minimum requirements and better. Please adjust the values to meet your or greater requirements.',
          without_rw_lnw_text: 'Without',
          rw: {
            main_text: 'Sound reduction index',
            info_description_text: 'Secondary sound transmission paths must also be taken into account. Please adjust the value accordingly. There may also be additional requirements for areas subject to greater exterior noise (e.g. road, rail or air traffic).',
            adaptation_text: 'Take the spectrum adaptation terms into account',
          },
          lnw: {
            main_text: 'Standard impact sound level',
            info_description_text: 'Secondary sound transmission paths must also be taken into account. Please adjust the value accordingly. There may also be additional requirements for areas subject to greater exterior noise (e.g. road, rail or air traffic).',
            adaptation_text: 'Take the spectrum adaptation terms into account',
          },
          fire_resistance_text: 'Resistance-to-fire classes',
          additional_specs_text: 'Additional requirements',
          mechanical_influence_text: 'M (mechanical influences)',
          cladding_criterion_text: {
            main_text: 'K{subText} 60 (cladding criterion)',
            sub_text: '2',
          },
          solid_wood_text: 'SWISS KRONO MAGNUMBOARD OSB',
          tooltips: {
            info_description_text: 'Secondary sound transmission paths must also be taken into account. Please adjust the value accordingly. There may also be additional requirements for areas subject to greater exterior noise (e.g. road, rail or air traffic).',
            airborn_insulation_text: 'Secondary sound transmission paths must also be taken into account. Please adjust the value accordingly. There may also be additional requirements for areas subject to greater exterior noise (e.g. road, rail or air traffic).',
            airborn_insulation_adaptation_text: 'The spectrum adaptation terms C100-3150 and C 50-3150 are correction factors for frequency drops in sound level curves for interior noise.',
            footfall_insulation_text: 'Secondary sound transmission paths must also be taken into account. Please adjust the value accordingly. There may also be additional requirements for areas subject to greater exterior noise (e.g. road, rail or air traffic).',
            footfall_insulation_adaptation_text: 'Correction factor for mainly low-frequency impact sound components. The spectrum adaptation term Cl50-2500 is for an extended frequency range from 50 to 2500 Hz for impact sound and from 100 to 3150 Hz for airborne sound.',
            fire_resistance_text: 'The resistance-to-fire classes F ... correspond to the requirements of DIN 4102-4:2016-05. The resistance-to-fire classes according to EN correspond to the Lignum document "4.1 Building Components in Timber Ceilings, Walls and Claddings with Fire Resistance".',
            additional_specs_text: 'Only building components with SWISS KRONO MAGNUMBOARD OSB may be used with MB.',
          },
        },
      },
    },
    selectors: {
      helper_text: 'Please choose',
      region_text: 'Region',
      building_type_text: 'Building',
      building_class_text: 'Building class',
      assembly_type_text: 'Component',
      assembly_type_specific_text: 'Specific components',
      download_link: 'https://www.swisskrono.de/assets/mime/-UTQ3ZXHNK372JEltBzA9IfGxDzQYjEpYV1isWvmBuVpMBf4QDbwa50P49eMDsJNRLMvS/P01400_SK_German-Building-Classes_EN_2011_a.pdf'
    },
    modal: {
      confirm_redirection_type: {
        title_text: 'Warning',
        description_text: 'You will lose your filtering options. Are you sure you want to continue?',
        first_button_text: 'Continue',
        second_button_text: 'Back',
      },
      disabled_assembly_type: {
        title_text: 'No selection possible',
        description_text: 'The selected building component is not available. Either it does not exist for the chosen building class, or it may not be made of timber. Please select a different component.',
        button_text: 'Ok',
      },
      disabled_assembly_type_specific: {
        title_text: 'No selection possible',
        description_text: 'The selected building component is not available. Either it does not exist for the chosen building class, or it may not be made of timber. Please select a different component.',
        button_text: 'Ok',
      },
      canceled_steps: {
        title_text: 'Are you sure?',
        description_text: 'If you go to another page, all progress will be lost',
        confirm_button_text: 'Continue',
        cancel_button_text: 'Back',
      },
    },
  },
  tools: {
    top_left_title_text: 'CALCULATION TOOLS',
    top_right_title_text: 'lets you use various calculation tools for free.',
    first_tool_title_text: 'U-VALUE-CALCULATOR',
    first_tool_subtitle_text: '',
    first_tool_main_text: 'Calculate the total thermal resistance value (R) and U-value as well as the values for the cross-sectional area and frame.',
    first_tool_sub_text: 'U-values are calculated as described in DIN 4108.',
    second_tool_title_text: 'CONDENSATION-CALCULATOR',
    second_tool_subtitle_text: '',
    second_tool_main_text: 'Determine, simply by selecting the materials used, whether the construction is free of condensation.',
    second_tool_sub_text: 'Condensation is calculated acc. to the Glaser method as described in DIN 4108-3.',
    third_tool_title_text: 'STRUCTURAL + FIRE PROTECTION',
    third_tool_subtitle_text: '',
    third_tool_main_text: 'Use the height and thickness of a MAGNUMBOARD wall to determine its maximum load factor and its resistance to fire to determine its burn rate.',
    third_tool_sub_text: 'Calculate the structural strength acc. to DIN EN 1995-1-1 and DIN EN 1995-1-2 (in cases of fire).',
  },
  impressum: {
    top_left_title_text: 'ABOUT THIS WEBSITE',
    top_right_title_text: 'Responsible for this website:',
    copyright_open_link_text: 'Open copyright',
  },
  data_privacy: {
    top_left_title_text: 'Privacy policy (Timberplanner.com)',
  },
  dialog: {
    buttons: {
      save_text: 'SAVE',
      save_changes_text: 'SAVE CHANGES',
      change_password_text: 'CHANGE PASSWORD',
      update_text: 'UPDATE',
      delete_account_text: 'DELETE ACCOUNT',
      continue_text: 'CONTINUE',
      log_in_text: 'LOG IN',
      register_text: 'REGISTER',
      cancel_text: 'CANCEL',
    },
  },
  product_details: {
    data_labels: {
      product_text: 'PRODUCT',
      manufacturer_text: 'MANUFACTURER',
      thickness_text: 'THICKNESS',
      weight_text: 'WEIGHT',
      width_text: 'WIDTH (B)',
      mast_distance_text: 'MUST DISTANCE (E)',
    },
    back_to_results_text: 'Back to list of results',
    save_bookmark_text: 'Bookmark',
    saved_bookmark_text: 'Bookmarked',
    download_ifc_text: 'Download IFC file',
    specifications_text: 'Specs GAEB XML',
    specifications_extra: 'Specs Word',
    layer_structure_label_text: 'LAYER STRUCTURE',
    first_tab_label_text: 'BASE COMPONENT',
    second_tab_label_text: 'SOUND INSULATION',
    third_tab_label_text: 'FIRE PROTECTION',
    installation_height_label_text: 'Installation height',
    mass_label_text: 'Weight',
    source_label_text: 'Source',
    airborne_sound_label_text: 'Airborne sound',
    footfall_label_text: 'Footfall',
    verified_calculation_label_text: 'Verified calculation',
    learn_acustic_label_text: 'You can learn more about how the acoustic insulation values are determined here:',
    certificate_label_text: 'Test certificate',
    fire_protection_class_label_text: 'Fire protection class',
    standard_source_label_text: 'Standard / Source',
    additional_requirements_label_text: 'Additional / extended requirements',
    first_tool_title_text: 'U-VALUE-CALCULATOR',
    second_tool_title_text: 'CONDENSATION-CALCULATOR',
    third_tool_title_text: 'STRUCTURAL + FIRE PROTECTION',
    more_informations_title_text: 'MORE INFORMATION',
    learn_more_text: 'Learn more about our products.',
    information_on_use_more_text: 'INFORMATION ON USE',
    find_important_informations_text: 'Here you can find all important information on using SWISS KRONO products.',
    export_building_text: 'Export the building component for other documentation',
    adaptation_value_text: 'Spectrum adaptation term:',
  },
  search_results: {
    more_results_loading_text: 'More systems are being loaded',
    results_found_text: 'COMPONENTS FOUND',
    filter_text: 'FILTER',
  },
  view_footer: {
    main_title_text: 'Swiss Krono\nPlanning',
    contact_text: 'Contact',
    contact_link: 'https://www.swisskrono.com/de-en/contact/#/',
    career_text: 'Career',
    career_link: 'https://www.swisskrono.com/ch-en/about-us/career/#',
    press_text: 'News & Events',
    press_link: 'https://www.swisskrono.com/de-en/about-us/news-events/#/',
    data_protection_text: 'Data privacy',
    impressum_text: 'Terms & Conditions',
    need_help_text: 'Do you need help?',
    glossary_text: 'Glossary',
    glossary_link: 'https://www.swisskrono.com/ch-en/glossary/#',
    downloads_link: 'https://www.swisskrono.com/de-en/download-area-wooden-building-materials/#/',
  },
  cookies: {
    cookies_text: 'Cookies facilitate the provision of our services. By using our services, you agree that we use cookies. For more information on privacy, please visit the following link ',
    cookies_data_protection_text: 'Data protection statement',
  },
  countries: {
    Afghanistan: "Afghanistan",
    Albania: "Albania",
    Algeria: "Algeria",
    American_Samoa: "American Samoa",
    Andorra: "Andorra",
    Angola: "Angola",
    Anguilla: "Anguilla",
    Antarctica: "Antarctica",
    Antigua_and_Barbuda: "Antigua and Barbuda",
    Argentina: "Argentina",
    Armenia: "Armenia",
    Aruba: "Aruba",
    Australia: "Australia",
    Austria: "Austria",
    Azerbaijan: "Azerbaijan",
    Bahrain: "Bahrain",
    Bangladesh: "Bangladesh",
    Barbados: "Barbados",
    Belarus: "Belarus",
    Belgium: "Belgium",
    Belize: "Belize",
    Benin: "Benin",
    Bermuda: "Bermuda",
    Bhutan: "Bhutan",
    Bolivia: "Bolivia",
    Bonaire_Sint_Eustatius_and_Saba: "Bonaire, Sint Eustatius and Saba",
    Bosnia_and_Herzegovina: "Bosnia and Herzegovina",
    Botswana: "Botswana",
    Bouvet_Island: "Bouvet Island",
    Brazil: "Brazil",
    British_Indian_Ocean_Territory: "British Indian Ocean Territory",
    British_Virgin_Islands: "British Virgin Islands",
    Brunei: "Brunei",
    Bulgaria: "Bulgaria",
    Burkina_Faso: "Burkina Faso",
    Burundi: "Burundi",
    Cambodia: "Cambodia",
    Cameroon: "Cameroon",
    Canada: "Canada",
    Cape_Verde: "Cape Verde",
    Cayman_Islands: "Cayman Islands",
    Central_African_Republic: "Central African Republic",
    Chad: "Chad",
    Chile: "Chile",
    China: "China",
    Christmas_Island: "Christmas Island",
    Cocos_Islands: "Cocos (Keeling) Islands",
    Colombia: "Colombia",
    Comoros: "Comoros",
    Congo: "Congo",
    Congo_Brazzaville: "Congo-Brazzaville",
    Cook_Islands: "Cook Islands",
    Costa_Rica: "Costa Rica",
    Cote_dIvoire: "Côte d’Ivoire",
    Croatia: "Croatia",
    Cuba: "Cuba",
    Curaçao: "Curaçao",
    Cyprus: "Cyprus",
    Czech_Republic: "Czech Republic",
    Denmark: "Denmark",
    Djibouti: "Djibouti",
    Dominica: "Dominica",
    Dominican_Republic: "Dominican Republic",
    Ecuador: "Ecuador",
    Egypt: "Egypt",
    El_Salvador: "El Salvador",
    Equatorial_Guinea: "Equatorial Guinea",
    Eritrea: "Eritrea",
    Estonia: "Estonia",
    Ethiopia: "Ethiopia",
    Falkland_Islands: "Falkland Islands",
    Faroes: "Faroes",
    Fiji: "Fiji",
    Finland: "Finland",
    France: "France",
    French_Guiana: "French Guiana",
    French_Polynesia: "French Polynesia",
    French_Southern_Territories: "French Southern Territories",
    Gabon: "Gabon",
    Gambia: "Gambia",
    Georgia: "Georgia",
    Germany: "Germany",
    Ghana: "Ghana",
    Gibraltar: "Gibraltar",
    Greece: "Greece",
    Greenland: "Greenland",
    Grenada: "Grenada",
    Guadeloupe: "Guadeloupe",
    Guam: "Guam",
    Guatemala: "Guatemala",
    Guernsey: "Guernsey",
    Guinea: "Guinea",
    Guinea_Bissau: "Guinea-Bissau",
    Guyana: "Guyana",
    Haiti: "Haiti",
    Heard_Island_and_McDonald_Islands: "Heard Island and McDonald Islands",
    Honduras: "Honduras",
    Hong_Kong_SAR_of_China: "Hong Kong SAR of China",
    Hungary: "Hungary",
    Iceland: "Iceland",
    India: "India",
    Indonesia: "Indonesia",
    Iran: "Iran",
    Iraq: "Iraq",
    Ireland: "Ireland",
    Isle_of_Man: "Isle of Man",
    Israel: "Israel",
    Italy: "Italy",
    Jamaica: "Jamaica",
    Japan: "Japan",
    Jersey: "Jersey",
    Jordan: "Jordan",
    Kazakhstan: "Kazakhstan",
    Kenya: "Kenya",
    Kiribati: "Kiribati",
    Kuwait: "Kuwait",
    Kyrgyzstan: "Kyrgyzstan",
    Laos: "Laos",
    Latvia: "Latvia",
    Lebanon: "Lebanon",
    Lesotho: "Lesotho",
    Liberia: "Liberia",
    Libya: "Libya",
    Liechtenstein: "Liechtenstein",
    Lithuania: "Lithuania",
    Luxembourg: "Luxembourg",
    Macao_SAR_of_China: "Macao SAR of China",
    Macedonia: "Macedonia",
    Madagascar: "Madagascar",
    Malawi: "Malawi",
    Malaysia: "Malaysia",
    Maldives: "Maldives",
    Mali: "Mali",
    Malta: "Malta",
    Marshall_Islands: "Marshall Islands",
    Martinique: "Martinique",
    Mauritania: "Mauritania",
    Mauritius: "Mauritius",
    Mayotte: "Mayotte",
    Mexico: "Mexico",
    Micronesia: "Micronesia",
    Moldova: "Moldova",
    Monaco: "Monaco",
    Mongolia: "Mongolia",
    Montenegro: "Montenegro",
    Montserrat: "Montserrat",
    Morocco: "Morocco",
    Mozambique: "Mozambique",
    Myanmar: "Myanmar",
    Namibia: "Namibia",
    Nauru: "Nauru",
    Nepal: "Nepal",
    Netherlands: "Netherlands",
    New_Caledonia: "New Caledonia",
    New_Zealand: "New Zealand",
    Nicaragua: "Nicaragua",
    Niger: "Niger",
    Nigeria: "Nigeria",
    Niue: "Niue",
    Norfolk_Island: "Norfolk Island",
    North_Korea: "North Korea",
    Northern_Marianas: "Northern Marianas",
    Norway: "Norway",
    Oman: "Oman",
    Pakistan: "Pakistan",
    Palau: "Palau",
    Palestine: "Palestine",
    Panama: "Panama",
    Papua_New_Guinea: "Papua New Guinea",
    Paraguay: "Paraguay",
    Peru: "Peru",
    Philippines: "Philippines",
    Pitcairn_Islands: "Pitcairn Islands",
    Poland: "Poland",
    Portugal: "Portugal",
    Puerto_Rico: "Puerto Rico",
    Qatar: "Qatar",
    Reunion: "Reunion",
    Romania: "Romania",
    Russia: "Russia",
    Rwanda: "Rwanda",
    Saint_Barthélemy: "Saint Barthélemy",
    Saint_Helena_Ascension_and_Tristan_da_Cunha: "Saint Helena, Ascension and Tristan da Cunha",
    Saint_Kitts_and_Nevis: "Saint Kitts and Nevis",
    Saint_Lucia: "Saint Lucia",
    Saint_Martin: "Saint Martin",
    Saint_Pierre_and_Miquelon: "Saint Pierre and Miquelon",
    Saint_Vincent_and_the_Grenadines: "Saint Vincent and the Grenadines",
    Samoa: "Samoa",
    San_Marino: "San Marino",
    Sao_Tome_e_Principe: "São Tomé e Príncipe",
    Saudi_Arabia: "Saudi Arabia",
    Senegal: "Senegal",
    Serbia: "Serbia",
    Seychelles: "Seychelles",
    Sierra_Leone: "Sierra Leone",
    Singapore: "Singapore",
    Sint_Maarten: "Sint Maarten",
    Slovakia: "Slovakia",
    Slovenia: "Slovenia",
    Solomon_Islands: "Solomon Islands",
    Somalia: "Somalia",
    South_Africa: "South Africa",
    South_Georgia_and_the_South_Sandwich_Islands: "South Georgia and the South Sandwich Islands",
    South_Korea: "South Korea",
    South_Sudan: "South Sudan",
    Spain: "Spain",
    Sri_Lanka: "Sri Lanka",
    Sudan: "Sudan",
    Suriname: "Suriname",
    Svalbard: "Svalbard",
    Swaziland: "Swaziland",
    Sweden: "Sweden",
    Switzerland: "Switzerland",
    Syria: "Syria",
    Taiwan: "Taiwan",
    Tajikistan: "Tajikistan",
    Tanzania: "Tanzania",
    Thailand: "Thailand",
    The_Bahamas: "The Bahamas",
    Timor_Leste: "Timor-Leste",
    Togo: "Togo",
    Tokelau: "Tokelau",
    Tonga: "Tonga",
    Trinidad_and_Tobago: "Trinidad and Tobago",
    Tunisia: "Tunisia",
    Turkey: "Turkey",
    Turkmenistan: "Turkmenistan",
    Turks_and_Caicos_Islands: "Turks and Caicos Islands",
    Tuvalu: "Tuvalu",
    US_Virgin_Islands: "US Virgin Islands",
    Uganda: "Uganda",
    Ukraine: "Ukraine",
    United_Arab_Emirates: "United Arab Emirates",
    United_Kingdom: "United Kingdom",
    United_States: "United States",
    United_States_Minor_Outlying_Islands: "United States Minor Outlying Islands",
    Uruguay: "Uruguay",
    Uzbekistan: "Uzbekistan",
    Vanuatu: "Vanuatu",
    Vatican_City: "Vatican City",
    Venezuela: "Venezuela",
    Vietnam: "Vietnam",
    Wallis_and_Futuna: "Wallis and Futuna",
    Western_Sahara: "Western Sahara",
    Yemen: "Yemen",
    Zambia: "Zambia",
    Zimbabwe: "Zimbabwe",
  },
};
